import React, { FC } from "react";

interface Props {}

export const Hero: FC<Props> = ({}) => {
  return (
    <div className="px-2 text-center">
      <h1 className="tracking-tighter">
        <div className="font-black text-8xl md:text-9xl">HERO</div>
        <div className="font-semibold text-5xl md:text-7xl">MESSENGER</div>
      </h1>
      <p className="mt-20 max-w-md mx-auto font-semibold text-black tracking-tight leading-tight text-2xl md:mt-15 md:text-4xl md:max-w-4xl ">
        Earn money by answering messages.
      </p>
      <p className="mt-6 max-w-md mx-auto font-semibold text-black tracking-tight leading-tight text-2xl md:mt-15 md:text-4xl md:max-w-4xl ">
        <span className="inline-block mb-1 px-2 pt-1 pb-2 text-blue-600 bg-blue-100 rounded">
          Let people message you,
        </span>
        <br />
        <span className="inline-block px-2 pt-1 pb-2 text-green-600 bg-green-100 rounded">
          get paid when you reply.
        </span>
      </p>
      <p className="mt-6 max-w-md mx-auto font-semibold text-black tracking-tight leading-tight text-2xl md:mt-15 md:text-4xl md:max-w-4xl ">
        It’s that easy.
      </p>
    </div>
  );
};
